import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { QrViewComponent } from '@app/components/qr-view/qr-view.component';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-more-tab',
  standalone: true,
  templateUrl: './more-tab.component.html',
  imports: [
    QrViewComponent,
    NgIf,
    TranslateModule,
    RouterLink,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./more-tab.component.scss'],
})
export class MoreTabComponent {
  public isQRVisible = false;
  public title = '';
  public description: string | undefined;
  public imgSrc = '';

  showQRCode(title: string, imgSrc: string, description?: string): void {
    this.isQRVisible = true;
    this.title = title;
    this.imgSrc = imgSrc;

    if (description) {
      this.description = description;
    }
  }

}
