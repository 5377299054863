import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-jaks-video',
  standalone: true,
  templateUrl: './jaks-video.component.html',
  imports: [
    NgIf,
  ],
  styleUrls: ['./jaks-video.component.scss'],
})
export class JaksVideoComponent implements AfterViewInit {
  @ViewChild('videoPlayer1') videoPlayer1!: ElementRef;
  @ViewChild('videoPlayer2') videoPlayer2!: ElementRef;
  @Input() videos: string[] = [];
  currentVideoIndex = 0;
  currentVideoPlayer!: ElementRef;
  loading = true;

  ngAfterViewInit(): void {
    const { index, startTime } = this.calculateVideoAndStartTime(60, this.videos.length);
    this.currentVideoIndex = index;
    this.videoPlayer1.nativeElement.src = this.videos[this.currentVideoIndex];
    this.videoPlayer1.nativeElement.currentTime = startTime;
    this.videoPlayer1.nativeElement.play();
    this.currentVideoPlayer = this.videoPlayer1;
    this.videoPlayer2.nativeElement.src = this.videos[(
      this.currentVideoIndex + 1
    ) % this.videos.length];
    this.videoPlayer2.nativeElement.pause();

    this.currentVideoPlayer.nativeElement.addEventListener('loadeddata', () => {
      this.loading = false;
    });
  }

  playNextVideo(): void {
    if (this.currentVideoIndex < this.videos.length - 1) {
      this.currentVideoIndex++;
    }
    else {
      this.currentVideoIndex = 0;
    }

    const nextVideoPlayer = this.currentVideoPlayer === this.videoPlayer1 ? this.videoPlayer2 : this.videoPlayer1;
    this.currentVideoPlayer.nativeElement.style.display = 'none';
    nextVideoPlayer.nativeElement.style.display = 'block';
    nextVideoPlayer.nativeElement.play();
    this.currentVideoPlayer.nativeElement.src = this.videos[(
      this.currentVideoIndex + 1
    ) % this.videos.length];
    this.currentVideoPlayer.nativeElement.pause();
    this.currentVideoPlayer = nextVideoPlayer;
  }

  calculateVideoAndStartTime(videoDuration: number, numberOfVideos: number): { index: number; startTime: number } {
    const currentTime = Date.now() / 1000;

    const totalDuration = videoDuration * numberOfVideos;
    const elapsedTime = currentTime % totalDuration;
    const index = Math.floor(elapsedTime / videoDuration);
    const startTime = elapsedTime - index * videoDuration;

    return { index, startTime };
  }
}
