<div class="tab-bar tab-bar__with-bg">
  <div class="tabs" #tabs coreActivitySection="TabBar">
    <button
      class="tab"
      [routerLink]="isPlayPage ? '/main/home' : '/main/play'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isPlayPage ? 'PlayPageClose' : 'PlayPageOpen'"
    >
      <div class="icon">
        <img src="/images/play.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isPlayPage">
        <span>{{ 'tabBar.play' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isGetRewardedPage ? '/main/home' : '/main/rewarded'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isGetRewardedPage ? 'GetRewardedPageClose' : 'GetRewardedPageOpen'"
    >
      <div class="icon">
        <img src="/images/get_rewarded.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isGetRewardedPage">
        <span>{{ 'tabBar.getRewarded' | translate }}</span>
      </div>
    </button>

    <button class="tab" routerLink='/main/home' coreUserActivityClickTracking="PageClose">
      <img class="logo" src="/images/logo.svg" alt=""/>
    </button>

    <button class="tab"
      [routerLink]="isWatchPage ? '/main/home' : '/main/watch'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isWatchPage ? 'WatchPageClose' : 'WatchPageOpen'"
    >
      <div class="icon">
        <img src="/images/watch.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isWatchPage">
        <span>{{ 'tabBar.watch' | translate }}</span>
      </div>
    </button>

    <button
      class="tab"
      [routerLink]="isMorePage ? '/main/home' : '/main/more'"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="isMorePage ? 'MorePageClose' : 'MorePageOpen'"
    >
      <div class="icon">
        <img src="/images/more.svg" alt=""/>
      </div>
      <div class="label" [class.active]="isMorePage">
        <span>{{ 'tabBar.more' | translate }}</span>
      </div>
    </button>
  </div>
</div>
