import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { QrViewComponent } from '@app/components/qr-view/qr-view.component';
import { NgIf } from '@angular/common';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-play-tab',
  standalone: true,
  templateUrl: './play-tab.component.html',
  imports: [
    TranslateModule,
    QrViewComponent,
    NgIf,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./play-tab.component.scss'],
})
export class PlayTabComponent {
  isQRVisible = false;
  gameName = '';
  imgSrc = '';

  showQRCode(name: string, imgSrc: string): void {
    this.isQRVisible = true;
    this.gameName = name;
    this.imgSrc = imgSrc;
  }
}
